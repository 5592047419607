<template>
  <div class="modal">
    <div :class="`card card__modal`">
      <div class="card__header">
        <h4 class="card__title">{{ $t('select-filters') }}</h4>
        <button class="btn btn--close-modal" @click.prevent="hideModal">
          <img src="/img/baseline_clear_white_48dp.png" :alt="$t('cancel')" :title="$t('cancel')" class="btn__img btn__img--small">
        </button>
      </div>
      <div class="card__content">
        <div class="card__content-columns">
          <input id="filterSearchField" type="text" class="input__search" :placeholder="$t('search')" v-model="searchText">
          <ol class="content-column">
            <template v-for="(category, categoryIndex) in fieldsMeta">
              <template v-if="categoryIndex % 2 === 0">
                <li :key="categoryIndex" class="column-row column-row-category">
                  {{ category.label }}
                </li>
                <template v-for="(value, valueIndex) in category.values">
                  <li :key="`${categoryIndex}-${valueIndex}`" class="column-row" v-if="value.label.toUpperCase().indexOf(searchText.toUpperCase()) > -1">
                    <input type="checkbox" class="input__checkbox" :class="[ fieldsSelection.includes(value.id) ? 'input__checkbox--checked' : '' ]" :id="`value-id-${value.id}`" :name="`value-id-${value.id}`" :value="value.id" :checked="fieldsSelection.includes(value.id)" @change="onFieldsSelectionChange">
                    <label class="input__label input__label--checkbox" :for="`value-id-${value.id}`">{{ value.label }}</label>
                  </li>
                </template>
              </template>
            </template>
          </ol>
          <ol class="content-column">
            <template v-for="(category, categoryIndex) in fieldsMeta">
              <template v-if="categoryIndex % 2 === 1">
                <li :key="categoryIndex" class="column-row column-row-category">
                  {{ category.label }}
                </li>
                <template v-for="(value, valueIndex) in category.values">
                  <li :key="`${categoryIndex}-${valueIndex}`" class="column-row" v-if="value.label.toUpperCase().indexOf(searchText.toUpperCase()) > -1">
                    <input type="checkbox" class="input__checkbox" :class="[ fieldsSelection.includes(value.id) ? 'input__checkbox--checked' : '' ]" :id="`value-id-${value.id}`" :name="`value-id-${value.id}`" :value="value.id" :checked="fieldsSelection.includes(value.id)" @change="onFieldsSelectionChange">
                    <label class="input__label input__label--checkbox" :for="`value-id-${value.id}`">{{ value.label }}</label>
                  </li>
                </template>
              </template>
            </template>
          </ol>
        </div>
        <div class="modal__actions">
          <div class="modal__actions--left">
            <button
              @click="hideModal"
              type="button" class="btn btn--text">{{ $t('cancel') }}</button>
          </div>
          <div class="modal__actions--right">
            <button
              @click="clearAll"
              type="button" class="btn btn--text">{{ $t('clear-all') }}</button>
            <button
              @click="changeFields"
              type="button" class="btn btn--ok">{{ $t('update') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// USO:
// <!-- FIELDS-SELECTOR-MODAL -->
// <fields-selector
//   id="fields-filter-selector"
//   v-if="isFieldsSelectorVisible"
//   :fields="fieldsFilter"
//   v-on:on-fields-filter-hide-modal="onFieldsFilterHideModal"
//   v-on:on-fields-filter-change="onFieldsFilterChange"></fields-selector>
// DRAG & DROP API: https://web.dev/drag-and-drop/
export default {
  name: 'fields-selector',
  props: [
    'fieldsFilter',
    'fieldsMeta'
  ],
  data () {
    return {
      fieldsSelection: [],
      searchText: ''
    }
  },
  mounted () {
    this.fieldsSelection = [...this.fieldsFilter]
  },
  methods: {
    hideModal () {
      this.$emit('on-fields-filter-hide-modal')
    },
    changeFields () {
      this.$emit('on-fields-filter-change', { fieldsFilter: [...this.fieldsSelection] })
      this.hideModal()
    },
    removeField (columnId) {
      this.fieldsSelection = [...this.fieldsSelection.filter(item => item !== columnId)]
    },
    clearAll () {
      this.fieldsSelection = []
    },
    // EVENTS
    onFieldsSelectionChange (e) {
      if (e.target.checked) {
        this.fieldsSelection = [...this.fieldsSelection, e.target.value]
      } else {
        this.fieldsSelection = [...this.fieldsSelection.filter(item => item !== e.target.value)]
      }
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<i18n>
{
  "en": {
    "select-filters": "Select Filters"
  },
  "es": {
    "select-filters": "Seleccinar filtros"
  }
}
</i18n>
<style scoped>
.modal {
  position: absolute;
  padding: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-content: center;
  box-shadow: 0 0 50px var(--app-shadow-color);
  border-radius: var(--card-border-radius);
}
.card__modal {
  width: 100%;
  max-width: calc(100% - 20px);
  min-height: 300px;
  margin: 0;
  padding: 30px;
  border-radius: 30px;
  display: grid;
  grid-template-rows: 60px 1fr;
  overflow: visible;
  background-color: var(--app-white);
}
.card__header {
  padding-bottom: 30px;
}
.card__title {
  font-size: 24px;
  line-height: 30px;
  height: 30px;
  font-weight: var(--app-font-bold);
}
.card__content {
  padding: 0;
}
.card__content-columns {
  display: grid;
  grid-template-rows: 30px 300px;
  grid-template-columns: 280px 280px;
  grid-template-areas:
    "top-row top-row"
    "col1 col2";
  gap: 20px;
}
#filterSearchField {
  grid-area: top-row;
}
.content-column {
  height: 300px;
  padding-right: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}
.column-row {
  margin-bottom: 10px;
}
.column-row-category {
  border-bottom: 1px solid var(--app-light-blue);
}
@media (min-width: 460px) {
  .card__modal {
    max-width: calc(100% - 50px);
  }
}
@media (min-width: 800px) {
  .card__modal {
    max-width: 800px;
  }
}
</style>
